import { CrisisAlert, LinkOff, Warning, WarningAmber } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { BESS } from './SingleLineCircuit';

import BatteryFalseFeedback from 'assets/singleline/battery_falsefeedback.svg';
import BatteryNoConnect from 'assets/singleline/battery_noconnect.svg';
import BatteryAlert from 'assets/singleline/battery_alert.svg';
import BatteryCritical from 'assets/singleline/battery_critical.svg';

type SingleLineAlertsProps = {
  BESSunits: BESS[];
};

export enum AlertType {
  CriticalAlert = 'Critical Alert',
  Alert = 'Alert',
  FalseFeedback = 'False Feedback',
  NoConnect = 'No Connect',
}

export type Alert = {
  type: AlertType;
  color: string;
  IconComponent: React.ElementType;
  batteryImage: string;
};

export type AlertsObject = {
  [key: string]: Alert;
};

export const alertsConfig: AlertsObject = {
  critical: {
    type: AlertType.CriticalAlert,
    color: '#D32F2F',
    IconComponent: CrisisAlert,
    batteryImage: BatteryCritical,
  },
  alert: {
    type: AlertType.Alert,
    color: '#ED6C02',
    IconComponent: Warning,
    batteryImage: BatteryAlert,
  },
  falsefeedback: {
    type: AlertType.FalseFeedback,
    color: grey[600],
    IconComponent: WarningAmber,
    batteryImage: BatteryFalseFeedback,
  },
  noconnect: {
    type: AlertType.NoConnect,
    color: grey[600],
    IconComponent: LinkOff,
    batteryImage: BatteryNoConnect,
  },
};

function AlertComponent({ alertKey, BESSunits }: { alertKey: string; BESSunits: BESS[] }) {
  const alert = alertsConfig[alertKey];
  const units = BESSunits.filter(bess => bess.alerts.includes(alertKey));

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" textAlign="center">
      <alert.IconComponent fontSize="large" sx={{ color: alert.color, margin: '0 auto' }} />
      <Typography variant="h5" color={alert.color} marginLeft={1}>
        {units.length} {alert.type}:{' '}
        {units.map(unit => (
          <Box
            component="span"
            key={unit.battery.id}
            color="white"
            bgcolor={alert.color}
            borderRadius="4px"
            p={0.5}
            ml={0.5}
            sx={{ whiteSpace: 'nowrap' }}>
            {unit.battery.name} {/* Display the name here */}
          </Box>
        ))}
      </Typography>
    </Box>
  );
}

export function SingleLineAlerts({ BESSunits }: SingleLineAlertsProps) {
  const validBESSunits = BESSunits.filter(bess => bess.alerts && bess.alerts.some(alert => alert.trim() !== ''));

  if (validBESSunits.length === 0) {
    return null;
  }

  const uniqueAlertKeys = Array.from(new Set(validBESSunits.flatMap(bess => bess.alerts)));

  const orderedAlertKeys = Object.keys(alertsConfig);

  uniqueAlertKeys.sort((a, b) => {
    const orderA = orderedAlertKeys.indexOf(a);
    const orderB = orderedAlertKeys.indexOf(b);
    return orderA - orderB;
  });

  return (
    <Box sx={{ display: 'flex', gap: 5, padding: 2, justifyItems: 'center', justifyContent: 'center' }}>
      {uniqueAlertKeys.map((alertKey, index) => (
        <AlertComponent key={index} alertKey={alertKey} BESSunits={BESSunits} />
      ))}
    </Box>
  );
}
